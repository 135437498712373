import { CARD_TYPE_MAP } from '@zola/zola-ui/src/paper/cards/constants/cardTypes';
import { STEPS } from './Steps';

export const TOOLTIP_DISABLED_DIGITAL_BUTTON =
  'Your guest list is missing some information. Please make sure all recipients have email addresses';
export const TOOLTIP_DISABLED_SEND_CARDS =
  'Your guest list is missing some information. Please make sure at least one recipient is selected and all required recipients information is provided';
export const TOOLTIP_DISABLED_MISSING_RECIPIENTS = 'Please select at least one recipient.';
export const TOOLTIP_DISABLED_CONTENT_TOO_LARGE =
  'Your content is too large for the box shown in red. Please increase box size or reduce font size to proceed';
export const TOOLTIP_DISABLED_NOTE_TOO_LONG =
  'One or more of your notes is too long to print. Shorten it or reduce the font size to continue.';
export const TOOLTIP_DISABLED_FINISH_ADDRESSING =
  'Finish up your guest addressing to continue. Or, turn off addressing below.';
export const TOOLTIP_DISABLED_TOO_MANY_ADDRESSES =
  'You currently have more addresses than envelopes selected. Please add more envelopes or reduce the number of addresses to continue.';

export const CARD_TYPES_SINGULAR = {
  [CARD_TYPE_MAP.invitation]: 'Invitation',
  [CARD_TYPE_MAP.invitationEnvelope]: 'Invitation Envelope',
  [CARD_TYPE_MAP.std]: 'Save the Date',
  [CARD_TYPE_MAP.ctd]: 'Change the Date',
  [CARD_TYPE_MAP.thankyou]: 'Thank You',
  [CARD_TYPE_MAP.menu]: 'Menu',
  [CARD_TYPE_MAP.program]: 'Program',
  [CARD_TYPE_MAP.place]: 'Place Card',
  [CARD_TYPE_MAP.holiday]: 'Holiday',
  [CARD_TYPE_MAP.enclosure]: 'Enclosure',
  [CARD_TYPE_MAP.rsvp]: 'RSVP',
  [CARD_TYPE_MAP.rsvpEnvelope]: 'RSVP Envelope',
  [CARD_TYPE_MAP.dstd]: 'Digital Save the Date',
};

export const SUMMARY_COPY = {
  [STEPS.detailFront]: {
    label: 'Front',
    description: 'Print on the front (required)',
  },
  [STEPS.detailInside]: {
    label: 'Inside',
    description: 'Print on the inside',
  },
  [STEPS.detailWrite]: {
    label: 'Write Notes',
    description: 'Print on the inside',
  },
  [STEPS.detailBack]: {
    label: 'Back',
    description: 'Print on the back',
  },
  [STEPS.detailAssign]: {
    label: 'Assign',
    description: 'Assign Guests',
  },
  [STEPS.recipients]: {
    label: 'Guests',
    description: 'Add guest addressing',
  },
  [STEPS.envelope]: {
    label: 'Envelope',
    description: 'Print mailing address',
  },
  [STEPS.rsvp]: {
    label: 'RSVP Card',
    description: 'Add RSVP cards & envelopes',
  },
  [STEPS.rsvpEnvelope]: {
    label: 'RSVP Envelope',
    description: 'Print your return address',
  },
  [STEPS.addMorePaper]: {
    label: 'Add More',
    description: 'Add matching programs, menus, and place cards',
  },
  [STEPS.front]: {
    label: 'Front',
    description: 'Digital save the day',
  },
  [STEPS.details]: {
    label: 'Details',
    description: 'Digital save the day',
  },
  [STEPS.links]: {
    label: 'Links',
    description: 'Digital save the day',
  },
  [STEPS.freeSamples]: {
    label: 'Free Samples',
    description: 'Digital save the day',
  },
  [STEPS.share]: {
    label: 'Share',
    description: 'Digital save the day',
  },
};

export const BREADCRUMB_GROUP_LABELS = {
  [CARD_TYPE_MAP.invitation]: 'Invitation',
  [CARD_TYPE_MAP.std]: 'Save The Date',
  [CARD_TYPE_MAP.ctd]: 'Change The Date',
  [CARD_TYPE_MAP.thankyou]: 'Thank You',
  [CARD_TYPE_MAP.menu]: 'Menu',
  [CARD_TYPE_MAP.program]: 'Program',
  [CARD_TYPE_MAP.holiday]: 'Holiday',
  [CARD_TYPE_MAP.enclosure]: 'Enclosure',
  [CARD_TYPE_MAP.rsvp]: 'RSVP',
  detailFront: 'Front',
  detailBack: 'Back',
  detailInside: 'Inside',
  detailWrite: 'Write Notes',
  detailAssign: 'Assign',
  ENVELOPE: 'Envelope',
  ADD_MORE: 'Add More',
  REVIEW: 'Review',
  front: 'Front',
  details: 'Details',
  links: 'Links',
  freeSamples: 'Free Samples',
  share: 'Send',
};

export const REVIEW_DOUBLE_CHECK_COPY = {
  default: 'Double-check all your images and wedding details before we print your work.',
  [CARD_TYPE_MAP.holiday]: 'Double-check all your images and details before we print your work.',
  [CARD_TYPE_MAP.place]:
    'Double-check your guest names and table numbers (if applicable) before we print your cards.',
  letterpress:
    "Double-check all your images and wedding details. Don't worry, <strong>we'll also review all your text</strong> to make sure everything is correct before we print your work.",
};

export const REVIEW_AGREEMENT_COPY = {
  default: 'All my text, dates, images, and information are correct. I’m ready to print my work!',
  proof: 'I acknowledge that I am ordering a single proof with placeholder text.',
  [CARD_TYPE_MAP.place]: 'I’ve checked that all of my names and tables are correct. Let’s print!',
};

export const REVIEW_BLANKS_TIP_COPY = {
  [CARD_TYPE_MAP.place]:
    'Pro Tip: Add some blank cards to account for last-minute guests and typos.',
};
