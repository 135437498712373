import { CARD_TYPE_MAP, LeadCardType } from '@zola/zola-ui/src/paper/cards/constants/cardTypes';
import { URL_SLUG_BY_CARD_TYPE } from 'cards/constants/urlSlugByCardType';

export const getUrlSlugByCardType = (
  cardType?: LeadCardType,
  isDigital?: boolean
): string | undefined => {
  const updatedType = isDigital ? CARD_TYPE_MAP.dstd : cardType;
  return (updatedType && URL_SLUG_BY_CARD_TYPE[updatedType]) || undefined;
};
