import { CARD_SIZES } from 'cards/constants/Cards';
import type { CardType } from '@zola/zola-ui/src/paper/cards/constants/cardTypes';

/** @deprecated use values directly or a type definition: type Orientation = 'PORTRAIT' | 'LANDSCAPE' (or svc-web-api types) */
export enum ORIENTATION {
  portrait = 'PORTRAIT',
  landscape = 'LANDSCAPE',
}

export type CardTemplateBackgroundsRequest = {
  cardType: CardType;
  orientation: ORIENTATION;
  size: keyof typeof CARD_SIZES;
  variationUuid?: string;
};
