import { STEPS, STEPS_ALLOWING_MULTIPLES } from '../constants/Steps';
import { getUrlSlugByCardType } from './getUrlSlugByCardType';

export const getStepUrl = (projectUUID, step, cardType, queryParams = null) => {
  let stepUrl;
  // If no project UUID is specified, URL can't be generated
  if (!projectUUID) return '';

  // If no step id is specified, always select the first step.
  const stepId = step || STEPS.detailFront;

  // create separated url for Digital Save the Date customization
  // TODO: DSTD
  if (cardType === 'DIGITAL_SAVE_THE_DATE') {
    stepUrl = `/wedding-planning/custom-digital/${projectUUID}/${stepId}`;

    if (queryParams) {
      stepUrl = `${stepUrl}?${queryParams.join('&')}`;
    }

    return stepUrl;
  }

  // When a card type is specified, find the type's url slug.
  let typeSlug;
  if (cardType && STEPS_ALLOWING_MULTIPLES.includes(stepId)) {
    typeSlug = getUrlSlugByCardType(cardType);
  }

  stepUrl = typeSlug
    ? `/wedding-planning/custom/${projectUUID}/${typeSlug}/${stepId}`
    : `/wedding-planning/custom/${projectUUID}/${stepId}`;

  if (queryParams) {
    stepUrl = `${stepUrl}?${queryParams.join('&')}`;
  }

  return stepUrl;
};
