import _without from 'lodash/without';

import ActionTypes from '../actions/draftsActionTypes';

const initialState = {
  busy: false,
  entities: {
    customizations: {},
    drafts: {},
  },
  allIds: [],
  isDraftInCartWarning: false,
};

export default function draftsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ActionTypes.REQUEST_DRAFTS: {
      return {
        ...state,
        busy: true,
      };
    }
    case ActionTypes.RECEIVE_DRAFTS: {
      const { drafts } = action.payload;
      return {
        ...state,
        entities: {
          ...state.entities,
          ...drafts.entities,
        },
        allIds: drafts.result,
        busy: false,
      };
    }
    case ActionTypes.RECEIVE_DELETE_PROJECT: {
      const { projectUUID } = action.payload;
      const allIds = _without(state.allIds, projectUUID);
      return {
        ...state,
        allIds,
      };
    }
    case ActionTypes.SET_DRAFT_IN_CART_WARNING: {
      const { isDraftInCartWarning } = action.payload;
      return {
        ...state,
        isDraftInCartWarning,
      };
    }
    case ActionTypes.RESET_DRAFT_IN_CART_WARNING: {
      return {
        ...state,
        isDraftInCartWarning: initialState.isDraftInCartWarning,
      };
    }
    default:
      return state;
  }
}
