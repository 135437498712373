import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _isNull from 'lodash/isNull';
import _omit from 'lodash/omit';
import _partition from 'lodash/partition';
import _reduce from 'lodash/reduce';
import _sortBy from 'lodash/sortBy';
import _union from 'lodash/union';
import _uniqBy from 'lodash/uniqBy';
import { normalize } from 'normalizr';
import { createLigaturesByFontId } from '@zola/zola-ui/src//util/font/fontLigatures';
import {
  findLeadCustomization,
  getTypeFromCustomization,
} from '@zola/zola-ui/src/paper/cards/util/customization';
import ActionTypes from '../actions/projectActionTypes';
import { STEPS } from '../constants/Steps';

import {
  getCustomizationNavData,
  updateCustomizationNavData,
} from '../util/customizationNavigation';
import projectSchema from '../schemas/projectSchema';

const fontVariationsMapper = (fonts, fontFamily) =>
  _reduce(
    fonts,
    (result, font) => {
      if (font.font_family === fontFamily) {
        result.push({
          id: font.id,
          displayName: font.display_name.replace(fontFamily, '').trim(),
          fontWeight: font.font_weight,
          fontStyle: font.font_style,
          fontFamily: font.font_family,
        });
      }
      // Here we split the list of variation on regular and non regular
      const splittedFonts = _partition(result, { fontWeight: 400 });
      // Brings "Regular" to the top as default and then the rest
      const topFonts = _sortBy(splittedFonts[0], topFont =>
        topFont.displayName === 'Regular' ? 0 : 1
      );
      const regularFonts = _sortBy(splittedFonts[1], ['fontWeight']);
      // Return a merge of both lists keeping custom order
      return _union(topFonts, regularFonts);
    },
    []
  );

const fontsMapper = fonts => {
  /**
   * Here we split the font list to separate recommended from not recommended
   * then we filter uniques from the not recommended list
   * then we merge them keeping order so we have recommended on top
   * once we have a proper list we reduce them so as to fill a new array with needed values
   * TODO: see if we can chain lodash methods https://lodash.com/docs/4.17.11#chain
   */
  const splittedResult = _partition(fonts, { template_referenced: true });
  const uniqueFonts = _uniqBy(splittedResult[1], 'font_family');
  const fontList = _union(splittedResult[0], uniqueFonts);
  const { mappedFonts, mappedNoteFonts } = _reduce(
    fontList,
    (result, font) => {
      // Only add fonts not in result array
      if (
        !_find(result.mappedFonts, { name: font.font_family }) &&
        (font.template_referenced || font.globally_available)
      ) {
        result.mappedFonts.push({
          allCaps: font.metadata?.all_caps_font || false,
          class: font.font_family.replace(/\s/g, '-').toLowerCase(),
          id: font.id,
          isGlobal: font.globally_available,
          name: font.font_family,
          recommended: font.template_referenced,
          isScriptFont: font.is_script_font,
          enableLigatures: font.enable_ligatures,
          type: font.metadata?.font_type,
          variations: fontVariationsMapper(fonts, font.font_family),
        });
      }
      if (!_find(result.mappedNoteFonts, { name: font.font_family }) && font.guest_data_available) {
        result.mappedNoteFonts.push({
          class: font.font_family.replace(/\s/g, '-').toLowerCase(),
          id: font.id,
          isGlobal: font.globally_available,
          name: font.font_family,
          recommended: font.template_referenced,
          isScriptFont: font.is_script_font,
          enableLigatures: font.enable_ligatures,
          type: font.metadata?.font_type,
          variations: fontVariationsMapper(fonts, font.font_family),
        });
      }
      return result;
    },
    { mappedFonts: [], mappedNoteFonts: [] }
  );

  return { mappedFonts, mappedNoteFonts };
};

export function projectMapper(project) {
  const customizationTypeToUUID = {};
  project.customizations.forEach(customization => {
    const customizationType = getTypeFromCustomization(customization);
    customizationTypeToUUID[customizationType] = customization.uuid;
  });
  return {
    ...project,
    customizationTypeToUUID,
  };
}

export const initialState = {
  currentPathname: '',
  error: null,
  uuid: null,
  type: null,
  splitOrderId: null,
  size: null,
  hasFoil: null,
  hasThemeColorPicker: null,
  suiteCollaborator: '',
  hasSeenProofModal: null,
  forceReviewPdf: false,
  optionValues: {},
  earliestArrivalDate: null,
  customizations: [],
  entities: {},
  isTestProject: false,
  ui: {
    activeField: null,
    breadcrumbs: [],
    orderedSteps: [],
    steps: {},
    stepGroups: [],
  },
  fontsByCustomizationUUID: {},
  customizationTypeToUUID: {},
  isFetching: false,
  busy: false,
  isAddingToCart: false,
  metaByProjectUUID: {},
  seededCardSuiteUUID: null,
  initializedCustomFoil: null,
  foilHistory: [],
  deletedFoilHistory: [],
  rsvpFoilHistory: [],
  rsvpDeletedFoilHistory: [],
  imageSizeRequirements: {
    minWidth: 0,
    minHeight: 0,
    minCrops: {},
  },
  isInCart: false,
  freeSamplesAvailable: null,
  pdfRenderDetails: {
    isBusy: false,
  },
  validationErrorsByCustomization: {},
  validationErrorsByElement: {},
};

function projectReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ActionTypes.CLEAR_PROJECT_ERROR: {
      return {
        ...state,
        error: initialState.error,
      };
    }
    case ActionTypes.SET_CURRENT_PATHNAME: {
      return { ...state, currentPathname: action.payload };
    }
    case ActionTypes.REQUEST_PROJECT: {
      return { ...state, isFetching: true };
    }
    case ActionTypes.RECEIVE_PROJECT: {
      const { project: receivedProject } = action.payload;

      if (receivedProject.status === 'error') {
        return {
          ...state,
          error: {
            message: receivedProject.message,
          },
        };
      }

      const rawProject = projectMapper(receivedProject);
      const { result: project, entities } = normalize(rawProject, projectSchema);
      const allCustomizations = Object.values(entities.customizations);

      const leadCustomization = findLeadCustomization(allCustomizations);

      const { medium } = leadCustomization;
      const optionValues = _get(leadCustomization, ['variation', 'option_values'], {});
      const earliestArrivalDate = _get(
        leadCustomization,
        ['variation', 'earliest_arrival_time'],
        null
      );
      const { size } = optionValues;
      const initializedFoilColor = optionValues['foil-color'] || 'none';

      const customizationType = getTypeFromCustomization(leadCustomization);

      let stepIdsToExclude = [];

      if (customizationType === 'DIGITAL_SAVE_THE_DATE') {
        const { freeSamplesAvailable } = state;
        stepIdsToExclude = !freeSamplesAvailable ? [STEPS.freeSamples] : [];
      }

      const holidaysShippingDetails = {
        [medium]: project.holiday_shipping_details,
      };

      const { breadcrumbs, orderedSteps, steps, stepGroups } = getCustomizationNavData(
        project.uuid,
        entities,
        rawProject.customizationTypeToUUID,
        leadCustomization,
        size,
        stepIdsToExclude
      );

      return {
        ...state,
        error: initialState.error,
        accountId: project.account_id,
        completedAt: project.completed_at,
        family: project.family,
        id: project.id,
        name: project.name,
        uuid: project.uuid,
        medium,
        type: getTypeFromCustomization(leadCustomization),
        size,
        hasFoil: project.has_foil,
        hasThemeColorPicker: project.supports_color_switching,
        suiteCollaborator: project.suite_collaborator || '',
        isInCart: project.in_cart,
        hasSeenProofModal: project.proof_modal_viewed,
        forceReviewPdf: project.force_review_pdf,
        customizations: project.customizations,
        customizationTypeToUUID: project.customizationTypeToUUID,
        entities,
        isTestProject: action.payload.isTestProject,
        earliestArrivalDate,
        holidaysShippingDetails,
        optionValues,
        seededCardSuiteUUID: project.seeded_card_suite_uuid,
        // * If a project have been started from a custom foil variation
        // * But the BE returns a project from a non custom foil variation
        // * Then, returned bool is false. Default is null.
        // * We never check for a truthy value here, so it's ignored
        // * Value is not persisted by the BE (only from the API layer),
        // * This bool is only updated when a project is created from the PDP
        initializedCustomFoil:
          !project.initialized_custom_foil && !_isNull(project.initialized_custom_foil)
            ? project.initialized_custom_foil
            : initialState.initializedCustomFoil,
        initializedFoilColor,
        foilHistory:
          initializedFoilColor && initializedFoilColor !== 'none' ? [initializedFoilColor] : [],
        ui: {
          ...state.ui,
          activeField: null,
          breadcrumbs,
          orderedSteps,
          steps,
          stepGroups,
        },
      };
    }
    case ActionTypes.RECEIVE_PROJECT_ERRORS: {
      const { customizations_with_errors: customizationsWithErrors } = action.payload;
      if (!customizationsWithErrors) return state;

      const validationErrorsByCustomization = {};
      const validationErrorsByElement = {};

      // For each customization containing elements with errors, only mention the FIRST error
      customizationsWithErrors.forEach(
        ({ customization_uuid: customizationUUID, elements_with_errors: elementsWithErrors }) => {
          validationErrorsByCustomization[customizationUUID] =
            elementsWithErrors?.[0]?.errors?.[0]?.customization_validation_error_type;

          // For each element with an error, only mention the FIRST error
          elementsWithErrors.forEach(element => {
            const { card_project_customization_element_uuid: elementUUID, errors } = element;
            validationErrorsByElement[elementUUID] =
              errors?.[0]?.customization_validation_error_type;
          });
        }
      );

      return {
        ...state,
        validationErrorsByCustomization,
        validationErrorsByElement,
      };
    }
    case ActionTypes.UPDATE_PLACE_CARD_CUSTOMIZATION: {
      const { customizationUUID, shouldPrintGuestNames, shouldPrintTableNames } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          customizations: {
            ...state.entities.customizations,
            [customizationUUID]: {
              ...state.entities.customizations[customizationUUID],
              should_print_guest_names: shouldPrintGuestNames,
              should_print_table_names: shouldPrintTableNames,
            },
          },
        },
      };
    }
    case ActionTypes.UPDATE_DIGITAL_DETAILS: {
      const { customizationUUID, digitalDetails } = action.payload;
      return {
        ...state,
        entities: {
          ...state.entities,
          customizations: {
            ...state.entities.customizations,
            [customizationUUID]: {
              ...state.entities.customizations[customizationUUID],
              digital_details: {
                ...state.entities.customizations[customizationUUID].digital_details,
                details: digitalDetails,
              },
            },
          },
        },
      };
    }
    case ActionTypes.RECEIVE_CUSTOMIZATION: {
      const {
        customization: { result: customization, entities },
      } = action.payload;
      const {
        variation: { option_values: optionValues, earliest_arrival_time: earliestArrivalDate },
        custom_notes_enabled: customNotesEnabled,
        type,
      } = customization;
      const customizationType = getTypeFromCustomization(customization);
      const { breadcrumbs, orderedSteps, steps, stepGroups } = updateCustomizationNavData(
        state.ui.orderedSteps,
        customNotesEnabled,
        type
      );
      return {
        ...state,
        earliestArrivalDate: earliestArrivalDate || state.earliestArrivalDate,
        optionValues,
        ui: {
          ...state.ui,
          breadcrumbs: breadcrumbs || state.ui.breadcrumbs,
          orderedSteps: orderedSteps || state.ui.orderedSteps,
          steps: steps || state.ui.steps,
          stepGroups: stepGroups || state.ui.stepGroups,
        },
        entities: {
          ...state.entities,
          elements: {
            ...state.entities.elements,
            ...entities.elements,
          },
          pages: {
            ...state.entities.pages,
            ...entities.pages,
          },
          customizations: {
            ...state.entities.customizations,
            [customization.uuid]: customization,
          },
        },
        customizationTypeToUUID: {
          ...state.customizationTypeToUUID,
          [customizationType]: customization.uuid,
        },
      };
    }
    case ActionTypes.RECEIVE_CUSTOMIZATION_ERRORS: {
      const {
        customization_uuid: customizationUUID,
        elements_with_errors: elementsWithErrors,
      } = action.payload;
      if (!elementsWithErrors) return state;

      // Only mention this customziation's FIRST error
      const validationErrorsByCustomization = {
        ...state.validationErrorsByCustomization,
        [customizationUUID]:
          elementsWithErrors[0]?.errors?.[0]?.customization_validation_error_type,
      };

      // Only mention each element's FIRST error
      const validationErrorsByElement = elementsWithErrors.reduce((acc, element) => {
        const { card_project_customization_element_uuid: uuid, errors } = element;
        return {
          ...acc,
          [uuid]: errors?.[0]?.customization_validation_error_type,
        };
      }, {});

      return {
        ...state,
        validationErrorsByCustomization,
        validationErrorsByElement,
      };
    }
    case ActionTypes.RECEIVE_ADD_TEXT_ELEMENT: {
      // set activeField to new element (if content_type is text) / insert in 'entities.elements'
      // then append element.uuid to array of associated page in entities.pages
      const { element, pageUUID } = action.payload;
      const page = state.entities.pages[pageUUID];
      return {
        ...state,
        ui: {
          ...state.ui,
          // only update activeField if content_type is text, prevents adding elements with linked element
          // from losing focus after cloned linked element is created (i.e. rsvp/meal option bullet point images)
          activeField: element.content_type === 'TEXT' ? element.uuid : state.ui.activeField,
        },
        entities: {
          ...state.entities,
          elements: {
            ...state.entities.elements,
            [element.uuid]: element,
          },
          pages: {
            ...state.entities.pages,
            [pageUUID]: {
              ...page,
              elements: [...page.elements, element.uuid],
            },
          },
        },
      };
    }

    case ActionTypes.RECEIVE_RESTORED_ELEMENT: {
      const { elements, lastChanges, pageUUID } = action.payload;
      const newElements = {};
      const newElementUUIDs = [];
      _forEach(elements, (elm, index) => {
        newElements[elm.uuid] = Object.assign({}, elm, lastChanges[index].value);
        newElementUUIDs.push(elm.uuid);
      });
      return {
        ...state,
        ui: {
          ...state.ui,
          activeField: elements.length > 1 ? elements[1].uuid : elements[0].uuid,
        },
        entities: {
          ...state.entities,
          elements: {
            ...state.entities.elements,
            ...newElements,
          },
          pages: {
            ...state.entities.pages,
            [pageUUID]: {
              ...state.entities.pages[pageUUID],
              elements: [...state.entities.pages[pageUUID].elements, ...newElementUUIDs],
            },
          },
        },
      };
    }

    // TODO: this could be named in a more generic way - RECEIVE_ELEMENT or something similar
    case ActionTypes.RECEIVE_DELETE_ELEMENT: {
      const { pageUUID, elementUUIDs } = action.payload;
      let nextElements = state.entities.elements;
      elementUUIDs.forEach(elementUUID => {
        nextElements = _omit(state.entities.elements, elementUUID);
      });
      return {
        ...state,
        ui: {
          ...state.ui,
          activeField: null, // remove focus
        },
        entities: {
          ...state.entities,
          elements: nextElements,
          pages: {
            ...state.entities.pages,
            [pageUUID]: {
              ...state.entities.pages[pageUUID],
              elements: state.entities.pages[pageUUID].elements.filter(
                uuid => !_includes(elementUUIDs, uuid)
              ),
            },
          },
        },
      };
    }
    case ActionTypes.RECEIVE_DELETE_ELEMENT_IMAGE: {
      const { elementUUID, element } = action.payload;
      return {
        ...state,
        entities: {
          ...state.entities,
          elements: {
            ...state.entities.elements,
            [elementUUID]: element,
          },
        },
      };
    }
    case ActionTypes.SET_ACTIVE_FIELD: {
      const { activeField } = action.payload;
      return {
        ...state,
        ui: {
          ...state.ui,
          activeField,
        },
      };
    }
    // Customization fonts actions
    case ActionTypes.GET_CUSTOMIZATION_FONTS_REQUEST: {
      return { ...state, isFetching: true };
    }
    case ActionTypes.GET_CUSTOMIZATION_FONTS_SUCCESS: {
      const { fonts, customizationUUID } = action.payload;
      const { mappedFonts, mappedNoteFonts } = fontsMapper(fonts);
      const ligaturesByFontId = createLigaturesByFontId(fonts);
      return {
        ...state,
        isFetching: false,
        fontsByCustomizationUUID: {
          ...state.fontsByCustomizationUUID,
          [customizationUUID]: {
            mapped: mappedFonts,
            mappedNote: mappedNoteFonts,
            raw: fonts,
            ligaturesByFontId,
          },
        },
      };
    }
    case ActionTypes.GET_CUSTOMIZATION_FONTS_FAILURE: {
      const { customizationUUID } = action.payload;
      return {
        ...state,
        isFetching: false,
        fontsByCustomizationUUID: {
          ...state.fontsByCustomizationUUID,
          [customizationUUID]: {},
        },
      };
    }
    case ActionTypes.REQUEST_ADD_PROJECT_TO_CART: {
      return { ...state, isAddingToCart: true };
    }
    case ActionTypes.RECEIVE_ADD_PROJECT_TO_CART: {
      return { ...state, isAddingToCart: false };
    }
    case ActionTypes.RECEIVE_PROJECT_META: {
      const { projectMeta, projectUUID } = action.payload;
      return {
        ...state,
        isFetching: false,
        metaByProjectUUID: {
          ...state.metaByProjectUUID,
          [projectUUID]: projectMeta,
        },
      };
    }
    case ActionTypes.ADD_TO_FOIL_HISTORY: {
      const steptype = action.payload.customizationCardType;
      const stateKeyToEdit = steptype === 'RSVP' ? 'rsvpFoilHistory' : 'foilHistory';
      const { newFoil } = action.payload;
      const newFoilHistory = state[stateKeyToEdit].slice(0);
      newFoilHistory.push(newFoil);
      return {
        ...state,
        [stateKeyToEdit]: newFoilHistory,
      };
    }
    case ActionTypes.REMOVE_ONE_FROM_FOIL_HISTORY: {
      const steptype = action.payload.customizationCardType;
      const stateKeyToEdit = steptype === 'RSVP' ? 'rsvpFoilHistory' : 'foilHistory';
      const newFoilHistory = state[stateKeyToEdit].slice(0);
      newFoilHistory.pop();
      return {
        ...state,
        [stateKeyToEdit]: newFoilHistory,
      };
    }
    case ActionTypes.ADD_TO_DELETE_HISTORY: {
      const steptype = action.payload.customizationCardType;
      const stateKeyToEdit = steptype === 'RSVP' ? 'rsvpDeletedFoilHistory' : 'deletedFoilHistory';
      const { newFoil } = action.payload;
      const newFoilHistory = state[stateKeyToEdit].slice(0);
      newFoilHistory.push(newFoil);
      return {
        ...state,
        [stateKeyToEdit]: newFoilHistory,
      };
    }
    case ActionTypes.REMOVE_ONE_FROM_DELETE_FOIL_HISTORY: {
      const steptype = action.payload.customizationCardType;
      const stateKeyToEdit = steptype === 'RSVP' ? 'rsvpDeletedFoilHistory' : 'deletedFoilHistory';
      const newFoilHistory = state[stateKeyToEdit].slice(0);
      newFoilHistory.pop();
      return {
        ...state,
        [stateKeyToEdit]: newFoilHistory,
      };
    }
    case ActionTypes.UPDATE_IMAGE_SIZE_REQUIREMENTS: {
      const { imageSizeRequirements } = action.payload;
      return {
        ...state,
        imageSizeRequirements,
      };
    }
    case ActionTypes.CHECK_FREE_SAMPLE_AVAILABILITY: {
      const { freeSamplesAvailable } = action.payload;

      const { breadcrumbs, orderedSteps, steps, stepGroups } = updateCustomizationNavData(
        state.ui.orderedSteps,
        false,
        'DIGITAL_SAVE_THE_DATE',
        freeSamplesAvailable
      );

      return {
        ...state,
        ui: {
          ...state.ui,
          breadcrumbs,
          orderedSteps,
          steps,
          stepGroups,
        },
        freeSamplesAvailable,
      };
    }
    case ActionTypes.SET_PROJECT_STEPS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          breadcrumbs: action.payload.breadcrumbs || state.ui.breadcrumbs,
          orderedSteps: action.payload.orderedSteps || state.ui.orderedSteps,
        },
      };
    }
    case ActionTypes.UPDATE_PROJECT_STEP: {
      const { stepId, updates } = action.payload;
      return {
        ...state,
        ui: {
          ...state.ui,
          orderedSteps: state.ui.orderedSteps.map(step => {
            if (step.id !== stepId) return step;

            return {
              ...step,
              ...updates,
            };
          }),
        },
      };
    }
    case ActionTypes.RECEIVE_PDF_REQUEST_DETAILS: {
      const {
        async_request_uuid: requestUUID,
        async_request_status: requestStatus,
        isBusy,
      } = action.payload;

      return {
        ...state,
        pdfRenderDetails: {
          ...state.pdfRenderDetails,
          isBusy,
          requestUUID,
          requestStatus,
        },
      };
    }
    case ActionTypes.REQUEST_PDF_REQUEST_DETAILS: {
      return {
        ...state,
        pdfRenderDetails: {
          ...state.pdfRenderDetails,
          isBusy: true,
        },
      };
    }
    default: {
      return state;
    }
  }
}

export default projectReducer;
