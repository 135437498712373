import { Maybe } from 'pratica';
import _get from 'lodash/get';
import _toLower from 'lodash/toLower';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import _keys from 'lodash/keys';
import _sumBy from 'lodash/sumBy';
import _reduce from 'lodash/reduce';
import type { WProjectCustomizationFullView } from '@zola/svc-web-api-ts-client';

import { CARD_TYPE_MAP } from '@zola/zola-ui/src/paper/cards/constants/cardTypes';
import { DEFAULT_QUANTITY } from 'cards/constants/Cards';
import { AnyAction } from 'redux';
import { getCustomizationSort } from '@zola/zola-ui/src/paper/cards/constants/customizationTypes';
import {
  findLeadCustomization,
  getTypeFromCustomization,
} from '@zola/zola-ui/src/paper/cards/util/customization';
import ActionTypes from './actionTypes';
import { pageName } from '../../util';
import { isCustomizationActive } from '../../util/isCustomizationActive';

const SUMMARY_LABEL_PER_TYPE = {
  [CARD_TYPE_MAP.thankyou]: {
    blank: '- Blank',
    printed: '- Printed Message',
  },
  [CARD_TYPE_MAP.place]: {
    blank: '(Blank)',
    printed: '(Printed)',
  },
};

const CUSTOM_STEP_PER_TYPE = {
  [CARD_TYPE_MAP.thankyou]: 'WRITE_NOTES',
  [CARD_TYPE_MAP.place]: 'ASSIGN',
};

export enum CUSTOMIZATION_FIELDS {
  none = 'NONE',
  background = 'BACKGROUND',
  photo = 'PHOTO_OPTIONS',
  text = 'TEXT_OPTIONS',
  layouts = 'LAYOUTS',
  qrCode = 'QR_CODE',
  guestList = 'GUEST_LIST',
  guestAddressing = 'GUEST_ADDRESSING',
  themeColors = 'THEME_COLORS',
  returnAddressing = 'RETURN_ADDRESSING',
}

export interface CustomizationState {
  activeCustomizationField: CUSTOMIZATION_FIELDS;
  activeGuestGroupId: number | null;
  review: {
    summary: any | null;
    notes: any[];
    total: {
      printed: number;
      blank: number;
      all: number;
    };
  };
  busy: boolean;
  hasPendingChanges: boolean;
}

const init: CustomizationState = {
  activeCustomizationField: CUSTOMIZATION_FIELDS.none,
  activeGuestGroupId: null,
  review: {
    summary: null,
    notes: [],
    total: {
      printed: 0,
      blank: 0,
      all: 0,
    },
  },
  busy: true,
  hasPendingChanges: false,
};

const metaMapper = ({ totalQuantity = 0, printedQuantity = 0, extraCostCents = 0 }) => {
  return {
    blanks: totalQuantity - printedQuantity,
    printed: printedQuantity,
    printed_extra_price_cents: extraCostCents,
  };
};

const Mapper = (project: any, meta: any) =>
  Maybe({ project, meta })
    .map(data => {
      let mappedCustomizations: any[] = [];
      const { project: projectData, meta: metaData } = data;
      const { customizations } = projectData;
      const leadCustomization = findLeadCustomization(
        customizations as WProjectCustomizationFullView[]
      );

      if (leadCustomization) {
        const {
          type: leadCustomizationType,
          quantity: leadCustomizationQuantity,
          guest_content_quantity: placeGuestQuantity,
          should_print_guest_names: isPlaceGuestEnabled,
        } = leadCustomization;
        const leadCardType = getTypeFromCustomization(leadCustomization);

        /**
         * Split is used to display separated summary entries for:
         * - Thank You with Custom Notes
         * - Place cards with Guests customizations
         *
         * For TY, printed/blank quantities are returned from the meta endpoint
         * For Place Cards, this quantities are returned on the customization data level
         */
        const isPlaceCardWithGuestsEnabled =
          leadCardType === CARD_TYPE_MAP.place && isPlaceGuestEnabled;
        const projectMetaFromType = isPlaceCardWithGuestsEnabled
          ? metaMapper({
              totalQuantity: leadCustomizationQuantity,
              printedQuantity: placeGuestQuantity,
            })
          : _get(metaData, _toLower(leadCustomizationType));
        const shouldSplitProject =
          projectMetaFromType &&
          projectMetaFromType.printed > 0 &&
          projectMetaFromType.blanks + projectMetaFromType.printed === leadCustomizationQuantity;

        if (shouldSplitProject) {
          // Set temporary blank customization object, from leadCustomization
          const blankCardsCustomization = Object.assign({}, leadCustomization, {
            // @ts-expect-error leadCardType needs validation
            name: `${leadCustomization.name} ${SUMMARY_LABEL_PER_TYPE[leadCardType].blank}`,
            quantity: projectMetaFromType.blanks,
            isCustomQtyEnabled: true,
          });

          // Set temporary printed customization object from leadCustomization
          const leadCardPrice = _get(leadCustomization, 'variation.price_cents') || 0;
          const printedCardsCustomization = Object.assign({}, leadCustomization, {
            // @ts-expect-error leadCardType needs validation
            name: `${leadCustomization.name} ${SUMMARY_LABEL_PER_TYPE[leadCardType].printed}`,
            quantity: projectMetaFromType.printed,
            variation: {
              ...leadCustomization.variation,
              price_cents: projectMetaFromType.printed_extra_price_cents + leadCardPrice,
              option_values: {
                ...(leadCustomization.variation?.option_values || {}),
                // Printed messages do not have foil
                foil: 'none',
                'foil-color': 'none',
              },
            },
            // @ts-expect-error leadCardType needs validation
            urlSlug: pageName(`${leadCardType}_${CUSTOM_STEP_PER_TYPE[leadCardType]}`),
            split: shouldSplitProject,
          });

          // Removed splitted customization and add the rest
          const remainderCustomizations = _filter(
            customizations,
            customization => customization.uuid !== leadCustomization.uuid
          );

          // For Place Cards, printed and blanks are shown in a reversed order, compared to Thank You cards
          const splittedCustomizations = isPlaceCardWithGuestsEnabled
            ? [printedCardsCustomization, blankCardsCustomization]
            : [blankCardsCustomization, printedCardsCustomization];
          mappedCustomizations = [...remainderCustomizations, ...splittedCustomizations];
        } else {
          _keys(customizations).map(uuid => mappedCustomizations.push(customizations[uuid]));
        }

        // All data sorted out, splited vs unsplited customizations
        const filteredCustomizations = mappedCustomizations.reduce((result, customization) => {
          if (customization && customization.variation) {
            const { variation } = customization;

            const cardType = getTypeFromCustomization(customization);
            const urlSlug = pageName(cardType);

            // make sure not to include any deleted customizations in order view
            // getProjectOrderView is used in subtotal selector so it's important to filter out deleted
            // customizations to avoid messing up subtotal
            if (variation && isCustomizationActive(customization)) {
              const orderView = {
                customizationUUID: customization.uuid,
                name: customization.name,
                type: customization.type,
                quantity: customization.quantity,
                defaultQuantity: DEFAULT_QUANTITY, // TODO: since qty of 1, is this still relevant?
                recipientAddressing: customization.recipient_addressing,
                urlSlug: customization.urlSlug || urlSlug,
                priceCents: variation.price_cents,
                imageUrls: variation.image_urls,
                color: variation.option_values.color,
                size: variation.option_values.size,
                paperType: variation.option_values['paper-type'] || null,
                liningColor: variation.option_values['envelope-lining'] || 'none',
                split: customization.split || false,
                isCustomQtyEnabled: customization.isCustomQtyEnabled || false,
                foilColor: variation.option_values['foil-color'],
              };
              result.push(orderView);
            }
          }
          return result;
        }, []);
        return _sortBy(filteredCustomizations, customization =>
          getCustomizationSort(getTypeFromCustomization(customization))
        );
      }
      return null;
    })
    .cata({
      Just: mappedSummary => mappedSummary,
      // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'state'.
      Nothing: () => state,
    });

// Actions

// Reducer
const reducer = (state: CustomizationState = init, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.REVIEW_SUMMARY_FETCH_FULFILLED:
      return Maybe(action.payload)
        .map(data => {
          const [project, meta] = data;
          const mappedSummary = Mapper(project, meta);
          const getGlobalPrice = (isDefaultPricing = false) =>
            _sumBy(
              mappedSummary,
              cust =>
                // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                cust.priceCents * (isDefaultPricing ? cust.defaultQuantity : cust.quantity) || 0
            );
          const priceSummary = _reduce(
            mappedSummary,
            (result, item) => {
              if (!item.split && item.urlSlug === 'detailInside') {
                Object.assign(result, { blank: item.priceCents * item.quantity || 0 });
              } else if (item.split && item.urlSlug === 'detailInside') {
                Object.assign(result, { printed: item.priceCents * item.quantity || 0 });
              }
              return result;
            },
            {}
          );

          return {
            ...state,
            review: {
              summary: mappedSummary,
              total: {
                ...priceSummary,
                all: getGlobalPrice(),
                default: getGlobalPrice(true),
              },
            },
          };
        })
        .cata({
          Just: updatedState => updatedState,
          Nothing: () => state,
        });
    case ActionTypes.CUSTOM_NOTES_FETCH_FULFILLED:
      return Maybe(action.payload)
        .map(notes => {
          const projectNotes = _reduce(
            notes,
            (result, item) => Object.assign(result, { [item.uuid]: item.data }),
            {}
          );
          return {
            ...state,
            busy: false,
            review: {
              ...state.review,
              notes: {
                ...projectNotes,
              },
            },
          };
        })
        .cata({
          Just: updatedState => updatedState,
          Nothing: () => state,
        });
    case ActionTypes.CUSTOM_NOTES_FETCH_REJECTED:
      return {
        ...state,
        busy: false,
      };
    case ActionTypes.SET_ACTIVE_CUSTOMIZATION_FIELD:
      return {
        ...state,
        activeCustomizationField: action.payload,
      };
    case ActionTypes.SET_ACTIVE_GUEST_GROUP_ID:
      return {
        ...state,
        activeGuestGroupId: action.payload,
      };
    case ActionTypes.SET_PENDING_CHANGES:
      return {
        ...state,
        hasPendingChanges: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
