// for general Cards:
export const BASE_QUANTITY_RANGE = [
  20,
  30,
  40,
  50,
  60,
  70,
  80,
  90,
  100,
  110,
  120,
  130,
  140,
  150,
  160,
  170,
  180,
  190,
  200,
  210,
  220,
  230,
  240,
  250,
  260,
  270,
  280,
  290,
  300,
  310,
  320,
  330,
  340,
  350,
  360,
  370,
  380,
  390,
  400,
];

// E.g. Place Cards allow for a min qty being 10
export const ADDITIONAL_QUANTITIES = [10];

export const DEFAULT_QUANTITY = 100;
export const DEFAULT_PAPER_TYPE_VALUE = 'smooth'; // use smooth as default to prevent bug with foil cards that dont have eggshell paper-type as an option
export const DEFAULT_SHAPE_VALUE = 'square';

export const ALL_FILTER_COLORS = [
  'BLACK',
  'BLUE',
  'BROWN',
  'GREEN',
  'GREY',
  'ORANGE',
  'PINK',
  'PURPLE',
  'RED',
  'WHITE',
  'YELLOW',
];

export enum SEO_COLOR {
  MULTI = 'MULTI',
  BLUE = 'BLUE',
  DUSTY_BLUE = 'DUSTY_BLUE',
  ROYAL_BLUE = 'ROYAL_BLUE',
  NAVY = 'NAVY',
  BLACK = 'BLACK',
  RED = 'RED',
  BURGUNDY = 'BURGUNDY',
  WHITE = 'WHITE',
  PURPLE = 'PURPLE',
  LAVENDER = 'LAVENDER',
  PLUM = 'PLUM',
  PINK = 'PINK',
  ROSE = 'ROSE',
  DUSTY_ROSE = 'DUSTY_ROSE',
  CORAL = 'CORAL',
  PEACH = 'PEACH',
  GREEN = 'GREEN',
  EMERALD_GREEN = 'EMERALD_GREEN',
  TEAL = 'TEAL',
  ORANGE = 'ORANGE',
  YELLOW = 'YELLOW',
  GREY = 'GREY',
  BROWN = 'BROWN',
  BEIGE = 'BEIGE',
  // GOLD = 'GOLD',
  // SILVER = 'SILVER',
  // ROSE_GOLD = 'ROSE_GOLD',
}

export const SEO_COLORS = [
  SEO_COLOR.MULTI,
  SEO_COLOR.BLUE,
  SEO_COLOR.DUSTY_BLUE,
  SEO_COLOR.ROYAL_BLUE,
  SEO_COLOR.NAVY,
  SEO_COLOR.BLACK,
  SEO_COLOR.RED,
  SEO_COLOR.BURGUNDY,
  SEO_COLOR.WHITE,
  SEO_COLOR.PURPLE,
  SEO_COLOR.LAVENDER,
  SEO_COLOR.PLUM,
  SEO_COLOR.PINK,
  SEO_COLOR.ROSE,
  SEO_COLOR.DUSTY_ROSE,
  SEO_COLOR.CORAL,
  SEO_COLOR.PEACH,
  SEO_COLOR.GREEN,
  SEO_COLOR.EMERALD_GREEN,
  SEO_COLOR.TEAL,
  SEO_COLOR.ORANGE,
  SEO_COLOR.YELLOW,
  SEO_COLOR.GREY,
  SEO_COLOR.BROWN,
  SEO_COLOR.BEIGE,
  // SEO_COLOR.GOLD,
  // SEO_COLOR.SILVER,
  // SEO_COLOR.ROSE_GOLD,
];

export const TEXT_ALIGNMENT_OPTIONS = ['LEFT', 'CENTER', 'RIGHT'];

export const PAGES = [
  'FRONT',
  'INSIDE',
  'WRITE',
  'BACK',
  'RSVP',
  'RSVP ENVELOPE',
  'ENVELOPE',
  'FRONT',
  'DETAILS',
  'FREE SAMPLES',
  'SHARE',
];
export const PAGE_URLS = {
  FRONT: 'detailFront',
  INSIDE: 'detailInside',
  WRITE: 'detailWrite',
  ASSIGN: 'detailAssign',
  BACK: 'detailBack',
  RSVP: 'rsvp',
  'RSVP ENVELOPE': 'rsvpEnvelope',
  ENVELOPE: 'envelope',
  'DIGITAL FRONT': 'front',
  DETAILS: 'details',
  'FREE SAMPLES': 'freeSamples',
  SHARE: 'share',
};

// for PLP:
export const REGULAR_COLORS = [
  'BLUE',
  'GREEN',
  'BLACK',
  'PINK',
  'PURPLE',
  'WHITE',
  'RED',
  'BEIGE',
  'GREY',
  'ORANGE',
  'YELLOW',
  'BROWN',
];

export const CARD_SIZES = {
  petite: 'petite',
  magnet: 'magnet',
  classic: '5x7',
  folded: 'folded-petite',
  postcard: 'postcard',
  tall: '4x8',
  smallFolded: 'folded-placecard-standard',
};

export const MIN_DPI = 300;

export const HIGHEST_ALLOWED_MIN_REQUIREMENT = 1050;

export const MAX_CARD_DIMENSION_INCHES = {
  [CARD_SIZES.petite]: 5.5,
  [CARD_SIZES.magnet]: 5.5,
  [CARD_SIZES.classic]: 7,
  [CARD_SIZES.folded]: 5.5, // height is smaller than width when folded, so use width
  [CARD_SIZES.postcard]: 5.5,
  [CARD_SIZES.tall]: 8,
  [CARD_SIZES.smallFolded]: 3.5,
};

export const SEASONS = ['Fall', 'Winter', 'Spring', 'Summer'];

export const FOLDED_SIZES = [CARD_SIZES.folded, CARD_SIZES.smallFolded];

export const SIZES_WITHOUT_SHAPES = [
  CARD_SIZES.postcard,
  CARD_SIZES.folded,
  CARD_SIZES.smallFolded,
];

export const DISABLED_FOLDED_PAPER = ['recycled', 'double-thick', 'triple-thick'];
export const DISABLED_POSTCARD_PAPER = [
  'pearlized',
  'linen',
  'felt',
  'double-thick',
  'triple-thick',
];

export const DISABLED_CUSTOM_FOIL_PAPER = ['felt'];

export const NON_STAMP_FOIL_VALUES = ['none', 'custom'];

// Used to align place card text elements via flexbox
export const VERTICAL_ALIGN_TO_ALIGN_ITEMS = {
  top: 'start',
  bottom: 'end',
  middle: 'center',
};
export const TEXT_ALIGN_TO_JUSTIFY_CONTENT = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
};
